import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/Solution',
    name: 'Solution',
    component: () => import('@/views/Solution.vue')
  },
  {
    path: '/Information',
    name: 'Information',
    component: () => import('@/views/Information.vue')
  },
  {
    path: '/Aboutus',
    name: 'Aboutus',
    component: () => import('@/views/Aboutus.vue')
  },
  {
    path: '/Jump',
    name: 'Jump',
    component: () => import('@/views/Jump.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
