<template>
  <div class="Nav">
    <ul class="w" style="padding-right: 100px !important">
      <li>
        <img
          class="pointer"
          src="@/assets/image/sprite/logo.png"
          @click="to('/Home')"
        />
      </li>
      <!-- <li>
        <img
          class="pointer"
          src="@/assets/image/sprite/technology.png"
          @click="toLink('./static/certificate/4.jpg')"
        />
      </li> -->

      <li class="pointer" @click="to('/Home')">首页</li>
      <li class="pointer showDropdown">
        解决方案
        <p class="deg">></p>
      </li>
      <div class="dropdown">
        <div class="w">
          <div
            class="dropdownItem pointer"
            @click="to('/Solution', { type: 1 })"
          >
            <span class="title">粮食管理解决方案</span>
            <span class="tip">助力企业实现精细化管理</span>
          </div>
          <div
            class="dropdownItem pointer"
            @click="to('/Solution', { type: 2 })"
          >
            <span class="title">销售管理解决方案</span>
            <span class="tip">建立完善的销售管理体系</span>
          </div>
          <div
            class="dropdownItem pointer"
            @click="to('/Solution', { type: 3 })"
          >
            <span class="title">GIS地图+保险解决方案</span>
            <span class="tip">助力企业实现精细化管理</span>
          </div>
          <div
            class="dropdownItem pointer"
            @click="to('/Solution', { type: 4 })"
          >
            <span class="title">安防管理解决方案</span>
            <span class="tip">打造安全真实的安防监管模式</span>
          </div>
        </div>
      </div>
      <li class="pointer" @click="to('/information')">资讯中心</li>
      <li class="pointer" @click="to('/Aboutus', { type: 1 })">关于我们</li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    to(path, query) {
      this.$router.push({
        path,
        query: query || {},
      });
    },
    toLink(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="less" scoped>
.Nav {
  min-width: 1200px !important;
  color: #f6f6f6;
  background-color: #00000066;
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: fixed;
  z-index: 9;
  .w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      img {
        height: 40px;
      }
    }
  }
  .deg {
    transform: rotate(90deg);
    display: inline-block;
  }
  .dropdown {
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    display: none;
    border-bottom: 1px solid #999;
    background-color: #ffffff;
    .dropdownItem {
      height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        color: #222222;
        line-height: 40px;
      }
      .tip {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #777777;
        line-height: 40px;
      }
    }
  }
  .showDropdown:hover + .dropdown {
    display: block;
  }
  .dropdown:hover {
    display: block;
  }
}
</style>
