<template>
  <div class="Footer">
    <div class="w">
      <div class="top">
        <div class="topItem">
          <div>解决方案</div>
          <div class="pointer" @click="to('/Solution', { type: 1 })">
            粮食管理解决方案
          </div>
          <div class="pointer" @click="to('/Solution', { type: 2 })">
            销售管理解决方案
          </div>
          <div class="pointer" @click="to('/Solution', { type: 3 })">
            GIS地图+保险解决方案
          </div>
          <div class="pointer" @click="to('/Solution', { type: 4 })">
            安防管理解决方案
          </div>
        </div>
        <div class="topItem">
          <div>一村资讯</div>
          <div class="pointer" @click="to('/Information')">行业动态</div>
          <div class="pointer" @click="to('/Information')">一村资讯</div>
        </div>
        <div class="topItem">
          <div>服务支持</div>
          <div class="pointer" @click="to('/Aboutus', { type: 1 })">
            关于我们
          </div>
          <div class="pointer" @click="to('/Aboutus', { type: 2 })">
            企业荣誉
          </div>
          <div class="pointer" @click="to('/Aboutus', { type: 3 })">
            企业文化
          </div>
          <div class="pointer" @click="to('/Aboutus', { type: 4 })">
            联系我们
          </div>
        </div>
        <div class="topCodeItem">
          <img src="@/assets/image/sprite/qrcode1.png" />
          <img src="@/assets/image/sprite/qrcode2.png" />
        </div>
      </div>
      <div class="bottom">
        <div class="bottomItem">
          <span class="pointer"
            >© 2021-2024 YICUNKEJI. ALL RIGHTS RESERVED.</span
          >
          <span>|</span>
          <span
            class="pointer"
            @click="toLink('https:\/\/beian.miit.gov.cn/#/Integrated/index')"
            >皖ICP备19017855号</span
          >
          <span>|</span>
          <span class="pointer" @click="toLink('./static/certificate/1.jpg')"
            >增值电信业务经营许可证</span
          >
          <span>|</span>
          <span
            class="pointer"
            @click="
              toLink(
                'http:\/\/www.beian.gov.cn/portal\/registerSystemInfo?recordcode=34010402703523'
              )
            "
            >皖公网安备34010402703523号</span
          >
        </div>
        <div class="bottomItem">
          <span class="pointer" @click="toLink('./static/certificate/2.jpg')"
            >预包装食品备案</span
          >
          <span>|</span>
          <span class="pointer" @click="toLink('./static/certificate/3.jpg')"
            >营业执照</span
          >
          <span>|</span>
          <span class="pointer" @click="toLink('./static/certificate/4.jpg')"
            >高新技术企业证书：GR202134004726</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    to(path, query) {
      this.$router.push({
        path: "/Jump",
        query: { path, query },
      });
    },
    toLink(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="less" scoped>
.Footer {
  min-width: 1200px !important
  ;
  height: 526px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 200;
  color: #ffffffaa;
  line-height: 40px;
  background: rgba(0, 4, 17, 0.82);
  .w {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    // align-items: center;
    .top {
      height: 383px;
      padding: 100px 0 60px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #f5f5f522;
      .topItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        img {
          width: 140px;
        }
        > div:first-child {
          font-size: 22px;
          color: #ffffff;
        }
      }
      .topCodeItem {
        width: 360px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        img {
          width: 140px;
        }
      }
    }
    .bottom {
      height: 143px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
